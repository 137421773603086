import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import InspectionCard from "../../models/InspectionCard";
import { useEffect, useState } from "react";
import { UserMeasurementService } from "../../services";
import { MeasurementUnit } from "../../data/entities";

export interface InspectionListItemChartProps {
  model?: InspectionCard;
}

export function InspectionListItemChart(props: Readonly<InspectionListItemChartProps>) {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const clonedData = (props?.model?.chart || []).map((v) => {
      return {
        startPosition: UserMeasurementService.convertValue(
          v.startPosition,
          MeasurementUnit.distanceLarge),
        maxValue: v.maxValue
      };
    });
    setData(clonedData);
  }, [props?.model?.chart])

  return (
    <Chart className="chart">
      <ChartPlotArea></ChartPlotArea>
      <ChartSeries>
        <ChartSeriesItem
          data={data}
          type="line"
          // eslint-disable-next-line react/style-prop-object
          style="normal"
          field="maxValue"
          categoryField="startPosition"
          markers={{ visible: false }}
          labels={{ visible: false }}
          color="#0D698B"
          width={1}
        />
      </ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem
          max={100}
          visible={false}
          majorGridLines={{
            visible: true,
            step: 2,
          }}
        ></ChartValueAxisItem>
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          baseUnitStep={20}
          labels={{
            step: 20,
            font: "9px Arial, Helvetica, Sans Serif",
            color: "#54534A",
            format: "n0",
          }}
          majorGridLines={{
            visible: false,
            step: 20,
          }}
          majorTicks={{
            step: 20,
          }}
          notes={{
            position: "bottom",
            icon: {
              visible: false,
              size: 15,
            },
            line: {
              length: 0,
            },
            label: {
              visible: true,
              color: "#808080",
              position: "outside",
              font: "9px Arial,Helvetica,sans-serif",
            },
            data: [
              { value: 0, label: { text: "core" } },
              { value: data.length, label: { text: "tail" } },
            ],
          }}
        ></ChartCategoryAxisItem>
      </ChartCategoryAxis>
    </Chart>
  );
}

export default InspectionListItemChart;
