import { Measurement as MeasurementModel, MeasurementUnit, SystemOfMeasurement } from "../../data/entities";
import { UserMeasurementService } from "../../services";

interface MeasurementProps {
  value: number;
  unit: MeasurementUnit;
  system?: SystemOfMeasurement;
  showValue?: boolean;
  showUnit?: boolean;
  convert?: boolean;
}

export function Measurement(props: MeasurementProps) {
  const formattedValue = UserMeasurementService.toFriendlyString(
    {
      unit: props.unit,
      value: props.value,
      system: props.system ?? ((props.convert ?? true) ?
        UserMeasurementService.getDefaultSystem() :
        UserMeasurementService.getSystem())
    } as MeasurementModel, props.showValue, props.showUnit);

  return <span className="fw-bold measurement">{formattedValue}</span>;
}

export function millimetersToInches(millimeters: number): number {
  return millimeters / 25.4;
}

export function inchesToMillimeters(inches: number): number {
  return inches * 25.4;
}

Measurement.defaultProps = {
  showValue: true,
  showUnit: true,
  convert: true,
};

export default Measurement;
