import React, { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import InspectionNoteDescription from "./InspectionNoteDescription";
import { v4 as uuidv4 } from "uuid";
import { Circle, Group, Path } from "@progress/kendo-drawing";
import { Point, Circle as gCircle } from "@progress/kendo-drawing/geometry";
import {
  AxisNoteVisualArgs,
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartPane,
  ChartPanes,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
  NoteClickEvent,
  NoteHoverEvent,
  SelectEndEvent,
  SeriesClickEvent,
} from "@progress/kendo-react-charts";
import { InspectionChartModel, MeasurementUnit } from "../../data/entities";
import { Icon, IconDefinitions, Measurement } from "../../components";
import CreateReferencePoint from "./CreateReferencePoint";
import { setActiveInspectionDetails, store } from "../../app/store/store";
import { showSuccessMessage } from "../../app/tools";
import { UserMeasurementService } from "../../services";

export interface ExploreCartChartProps {
  chartSeries: string;
  chartData: InspectionChartModel;
  overallThreshold?: number;
}

interface ToastControlStructure {
  key: string;
  element: React.JSX.Element | null;
  visible: boolean;
}

export default function ExploreCardChart(props: Readonly<ExploreCartChartProps>) {
  const chartData = props.chartData;
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [step, setStep] = useState<any>({ step: 0 });
  const [chartTitle, setChartTitle] = useState<string | undefined>(undefined);
  const [toastList, setToastList] = useState<ToastControlStructure[]>([]);
  const [toastKeyToClose, setToastKeyToClose] = useState("");
  const [showReferencePoint, setShowReferencePoint] = useState(false);
  const [pointData, setPointData] = useState<any>(undefined);

  const converter = UserMeasurementService.getConverter();

  useEffect(() => {
    const upperLimit = chartData.points.length ?? 0;
    setMax(upperLimit);
    setStep({ step: Math.floor((upperLimit - 0) / 10) });
    return () => {};
  }, [chartData]);

  useEffect(() => {
    const newList = toastList.filter((t) => t.key !== toastKeyToClose);
    setToastList(newList);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastKeyToClose]);

  const onSelectEnd = (e: SelectEndEvent) => {
    const fromIndex = e.from;
    const toIndex = e.to;
    const fromDistance = Math.round(points[fromIndex].position);
    const toDistance = Math.round(points[toIndex-1].position);
    setMin(e.from);
    setMax(e.to);

    if (e.from === 0 && e.to === points.length) {
      setChartTitle(undefined);
      return;
    }
    
    let unitAbbreviation = UserMeasurementService.getAbbreviation(MeasurementUnit.distanceLarge);

    const start = e.from === 0 ? "Start" : `${fromDistance}${unitAbbreviation}`;
    const end = e.to === points.length ? "End" : `${toDistance}${unitAbbreviation}`;
    setChartTitle(`${start} - ${end}`);
  };

  const onNoteClick = (e: NoteClickEvent) => {
    const key = uuidv4();
    const newToast = (
      <Toast
        key={key}
        onClose={() => setToastKeyToClose(key)}
        show={true}
        delay={5000}
        autohide={true}
      >
        <Toast.Header>
          <div className="me-auto">
            <small>Distance from core: {e.dataItem.distance}</small>
          </div>
        </Toast.Header>
        <Toast.Body>
          <InspectionNoteDescription
            severity={e.dataItem.severity as string}
            description={e.dataItem.description as string}
          />
          <a href={`#${e.dataItem.noteId}`}>
            View Note Details <Icon iconName={IconDefinitions.angleRight} />
          </a>
        </Toast.Body>
      </Toast>
    );
    const newList = [
      ...toastList,
      { key: key, element: newToast, visible: true },
    ];
    setToastList(newList);
  };

  const onNoteHover = (e: NoteHoverEvent) => {};

  const getNoteColor = (severity: string | undefined) => {
    switch ((severity ?? "").toUpperCase()) {
      case "URGENT":
        return "#DC3545";
      case "COMPROMISEDOD":
      case "CAUTION":
      case "PREREPAIR":
        return "#FFC107";
      case "REPAIRED":
      case "POSTREPAIR":
        return "#28A745";
      case "COMMENT":
      default:
        return "#D0D0D0";
    }
  };

  const onNoteVisual = (e: AxisNoteVisualArgs) => {
    const severity = (e as any).dataItem.severity;

    const x = e.rect.origin.x;
    const y = e.rect.origin.y;

    const line = new Path({
      stroke: {
        width: 3,
        color: "#54534A",
      },
    })
      .moveTo(x, y)
      .lineTo(x, y + 8);

    const geometry = new gCircle(new Point(x, y + 15), 7);
    const circle = new Circle(geometry, {
      fill: {
        color: getNoteColor(severity),
        opacity: 0.75,
      },
      stroke: {
        width: 1,
        color: "#54534A",
      },
      cursor: "pointer",
    });

    const group = new Group();
    group.append(line, circle);
    return group;
  };

  const onSeriesClick = (e: SeriesClickEvent) => {
    const inspectionId = chartData.id;
    const categoryPosition = e.category as number;
    const pointIndex = points.findIndex((p) => p.position === categoryPosition);

    const pointData = {
      inspectionId,
      categoryPosition,
      pointIndex,
    };
    setPointData(pointData);
    setShowReferencePoint(true);
  };

  const handleModalClose = (created: boolean) => {
    if (created) {
      showSuccessMessage(
        "Data segment split and positions recalculated, the page will now refresh."
      );
      store.dispatch(setActiveInspectionDetails(undefined));
    }
    setShowReferencePoint(false);
  };

  const points = [...chartData.points];
  const categoryChartData = points.map((p) => p.position);
  const seriesChartData = points.map((p) => {
    const obj = p as any;
    return obj[props.chartSeries] as number;
  });
  const threshold = points.map((p) => {
    return props.overallThreshold;
  });
  const defaultStep = Math.floor(points.length / 10);
  let chartSeriesItemList = [
    <ChartSeriesItem
      key={`s1`}
      data={seriesChartData}
      type="line"
      // eslint-disable-next-line react/style-prop-object
      style="normal"
      markers={{ visible: false }}
      labels={{ visible: false }}
      width={1}
      color={"#0D698B"}
      name=""
    >
      <ChartSeriesItemTooltip
        visible={true}
        render={(c) => {
          return (
            <div>
              <b>
                <Measurement
                  unit={MeasurementUnit.distanceLarge}
                  value={c.point.category as number}
                  system={converter.system}
                  convert={false}
                />
              </b>
              &nbsp;{`${c.point.value}%`}
            </div>
          );
        }}
      />
    </ChartSeriesItem>,
    <ChartSeriesItem
      key={`s2`}
      data={seriesChartData}
      type="area"
      // eslint-disable-next-line react/style-prop-object
      style="smooth"
      axis="valueNavigatorAxis"
      categoryAxis="navigatorAxis"
      name=""
      color={"#505050"}
      width={1}
    />,
    <ChartSeriesItem
      key={`s1`}
      data={threshold}
      visible={!!props.overallThreshold}
      color={"#0c0"}
      type="line"
      // eslint-disable-next-line react/style-prop-object
      style="normal"
      markers={{ visible: false }}
      labels={{ visible: false }}
      width={1}
      name="Inspection Threshold %"
    >
      <ChartSeriesItemTooltip
        visible={true}
        render={(c) => {
          return (
            <div>
              <b>
                <Measurement
                  unit={MeasurementUnit.distanceLarge}
                  value={c.point.category as number}
                  system={converter.system}
                  convert={false}
                />
              </b>
              &nbsp;{`${c.point.value}%`}
            </div>
          );
        }}
      />
    </ChartSeriesItem>,
  ];

  return (
    <>
      <Chart
        className="chart"
        onSelectEnd={onSelectEnd}
        onNoteClick={onNoteClick}
        onNoteHover={onNoteHover}
        onSeriesClick={onSeriesClick}
      >
        <ChartTitle text={chartTitle}></ChartTitle>
        <ChartLegend visible={true} position="top" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            min={min}
            max={max}
            labels={{
              visible: false,
            }}
            majorGridLines={step}
            majorTicks={step}
            categories={categoryChartData}
          />
          <ChartCategoryAxisItem
            categories={categoryChartData}
            name="navigatorAxis"
              title={{ text: `Length (${UserMeasurementService.getAbbreviation(MeasurementUnit.distanceLarge)})` }}
            labels={{
              step: defaultStep,
              format: "#",
              margin: { top: 20 },
              font: "9px Arial, Helvetica, Sans Serif",
            }}
            majorGridLines={{ step: points.length / 40 }}
            majorTicks={{ step: defaultStep }}
            pane="navigator"
            select={{ from: min, to: max }}
            notes={{
              position: "bottom",
              icon: {
                size: 15,
                // color:
              },
              label: {
                visible: false,
              },
              visual: onNoteVisual,
              data: chartData.notes.map((note) => {
                const closest = points.reduce((prev, curr) => {
                  const prevDiff = Math.abs(prev.position - note.position);
                  const currDiff = Math.abs(curr.position - note.position);
                  return currDiff < prevDiff ? curr : prev;
                });

                const index = points.findIndex(
                  (p) => p.position === closest.position
                );
                return {
                  value: index,
                  label: {
                    text: `${note.position} -  ${note.description}`,
                  },
                  distance: note.position,
                  description: note.description,
                  severity: note.severity,
                  noteId: note.id,
                };
              }),
            }}
          />
        </ChartCategoryAxis>
        <ChartPanes>
          <ChartPane />
          <ChartPane name={"navigator"} height={100} />
        </ChartPanes>
        <ChartValueAxis>
          <ChartValueAxisItem
            title={{ text: "Percent (%)" }}
            max={100}
            labels={{visible: false}}
            visible={true}
            majorGridLines={{
              visible: true,
              step: 2,
            }}
          ></ChartValueAxisItem>
          <ChartValueAxisItem
            visible={false}
            minorGridLines={{ visible: false }}
            majorGridLines={{ visible: false }}
            name="valueNavigatorAxis"
            pane="navigator"
          />
          <ChartValueAxisItem
            visible={false}
            max={100}
            labels={{visible: false}}
            line={{ visible: false }}
            plotBands={[
              {
                from: 0,
                to: props.overallThreshold,
                color: "#0c0",
                opacity: 0.07,
              },
            ]}
            majorGridLines={{ visible: true,step: 2,}}
            minorGridLines={{ visible: false }}
            majorTicks={{ visible: false }}
            minorTicks={{ visible: false }}
            axisCrossingValue={0}
          />
        </ChartValueAxis>
        <ChartSeries>{chartSeriesItemList}</ChartSeries>
      </Chart>
      <ToastContainer
        className="pe-2"
        style={{ paddingBottom: 80 }}
        position="bottom-end"
      >
        {toastList.map((toast) => toast.element)}
      </ToastContainer>
      <CreateReferencePoint
        pointData={pointData}
        show={showReferencePoint}
        handleClose={handleModalClose}
      />
    </>
  );
}


