import InspectionCard from "../../models/InspectionCard";
import { isDate, toYearMonthDayString } from "../../app/tools";
import UserModel from "../../data/entities/UserModel";
import Gravatar from "react-gravatar";
import { v4 as uuidv4 } from "uuid";
import Link from "../link/Link";
import { routes } from "../../app/routes";
import Measurement from "./Measurement";
import InspectionListItemChart from "./InspectionListItemChart";
import { MeasurementUnit } from "../../data/entities/MeasurementUnit";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { LinkLooksLike } from "../link";
import { Icon, IconAndText, IconDefinitions } from "../icons";
import InspectionFileExporter from "./InspectionFileExporter";
import { AreaExport, InspectionService, UserService } from "../../services";
import { FileExportType, Roles } from "../../models";

export interface InspectionListItemProps {
  model?: InspectionCard;
}

export function InspectionListItem(props: InspectionListItemProps) {
  const { model } = props;

  const inspectionDate = model?.inspection?.inspectionDate;
  let inspectionDateString: string = "NO DATE";

  if (inspectionDate && isDate(inspectionDate)) {
    inspectionDateString = toYearMonthDayString(new Date(inspectionDate));
  }

  const getDisplayName = (user: UserModel) => {
    return `${user.firstName} ${user.lastName}`.trim() === ""
      ? user.userName
      : `${user.firstName} ${user.lastName}`;
  };

  let inspectors: JSX.Element[] = [];
  model?.inspectors.forEach((i) => {
    inspectors.push(
      <li className="mr-2" key={uuidv4()}>
        <Gravatar
          email={i.email}
          size={128}
          default="identicon"
          alt={`${i.userName} Gravatar`}
          title={getDisplayName(i)}
        />
      </li>
    );
  });

  const inspectionParams = new Map<string, string>([
    ["id", model?.inspection.id?.toString() ?? ""],
  ]);

  const fileExporter = (content: JSX.Element | string) => {
    const sources = new InspectionService().getExportUrls(
      model?.inspection.id ?? 0,
      AreaExport.inspection,
      `${model?.tubing?.name}-${inspectionDateString}`
    );

    const source = sources.find((s) => s.type === FileExportType.Report);

    return (
      <InspectionFileExporter
        inspectionId={model?.inspection.id ?? 0}
        source={source!}
        onClick={() => {
          document.body.click();
          return false;
        }}
      >
        {content}
      </InspectionFileExporter>
    );
  };

  const generalContent = () => {
    if (
      UserService.isInRole(Roles.staff) ||
      UserService.isInRole(Roles.admin)
    ) {
      const tubingParams = new Map<string, string>([
        ["id", model?.tubing?.id?.toString() ?? ""],
      ]);

      return (
        <>
          <hr className="m-0" />
          <Link url={routes.inspectionsDetails} params={inspectionParams}>
            Edit Inspection
          </Link>
          <Link url={routes.tubingDetails} params={tubingParams}>
            Edit Tubing
          </Link>
        </>
      );
    }
  };

  const adminContent = () => {
    if (UserService.isInRole(Roles.admin)) {
      const companyParams = new Map<string, string>([
        ["id", model?.tubing?.companyId?.toString() ?? ""],
      ]);

      return (
        <>
          <hr className="m-0" />
          <Link url={routes.companyDetails} params={companyParams}>
            Edit Company
          </Link>
        </>
      );
    }
  };

  const popover = (
    <Popover id={`popover-${model?.inspection.id}`}>
      <Popover.Body>
        <div className="d-grid gap-2">
          <Link url={routes.myInspectionsDetails} params={inspectionParams}>
            View Inspection
          </Link>
          {fileExporter("Download Report")}
          {generalContent()}
          {adminContent()}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-3">
      <div className="inspection-card card">
        <div className="card-title bg-secondary-dark text-light pb-1">
          <div className="row mx-0">
            <div className="col-sm-9 pt-3 line-spacing-normal">
              <h6 className="m-0 text-light">
                {model?.tubing?.name ?? "NO NAME"}
              </h6>
              <span className="text-sm">{inspectionDateString}</span>
              <br />
              <span className="text-sm text-truncate">{`${(model?.inspection.address?.streetAddress)?.trim()} ${(model?.inspection.address?.city)?.trim()} ${(model?.inspection.address?.province?.abbreviation)}, ${model?.inspection.address?.province?.countryCode}`}</span>
              <br />
              <br />
              <span className="text-sm">
                Inspected {model?.inspection?.direction ?? "NO DIRECTION"}
              </span>
              <br />
              <span className="text-sm">
                OD Size: {model?.tubing?.sizeDescription}
              </span>
            </div>
            <div className="col-sm-3 pt-3 text-end context-menu">
              <OverlayTrigger
                trigger="click"
                placement="left-start"
                overlay={popover}
                rootClose
              >
                {({ ref, ...triggerHandler }) => (
                  <LinkLooksLike ref={ref} {...triggerHandler}>
                    <Icon
                      iconName={IconDefinitions.ellipsisVertical}
                      className="clickable text-light"
                    />
                  </LinkLooksLike>
                )}
              </OverlayTrigger>
            </div>
          </div>
          <div className="px-3 py-0 fs-1">
            <Measurement
              value={model?.tubing.length ?? 0}
              unit={MeasurementUnit.distanceLarge}
            />
          </div>
          <div className="inspection-card-inspectors">
            <ul>{inspectors}</ul>
          </div>
        </div>
        <div className="card-body px-2 pt-3 pb-2">
          <InspectionListItemChart model={model} />
        </div>
        <div className="list-group list-group-flush">
          <div className="d-flex p-3 align-items-baseline">
            <Link
              url={`${routes.myInspections}/${model?.inspection.id}/details`}
              className="card-link text-sm"
            >
              <IconAndText
                iconName={IconDefinitions.search}
                text="View"
              />
            </Link>
            <span className=" ms-2">
              {fileExporter(
                <IconAndText
                  iconName={IconDefinitions.pdf}
                  text="Download"
                  className="card-link text-sm"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InspectionListItem;

