import React, { useEffect, useMemo, useState } from "react";
import Submenu from "../../components/submenu/Submenu";
import { getRouteParams, routes } from "../../app/routes";
import { useLocation } from "react-router-dom";
import InspectionDetails from "../../data/entities/InspectionDetailsModel";
import InspectionService from "../../services/InspectionService";
import "../../css/bootstrap.scss";
import { ExploreCard } from "./ExploreCard";
import { FLWCard } from "./FLWCard";
import { setActiveInspectionId } from "../../app/store/inspectionSlice";
import InspectorList from "../../components/inspections/InspectorList";
import InspectionSummary from "./InspectionSummary";
import InspectionCriteria from "./InspectionCriteria";
import InspectionNotes from "./InspectionNotes";
import {
  SimpleCard,
  SpinnerDefault,
  SubmenuNavigationItem,
} from "../../components";
import { store } from "../../app/store/store";
import PageContent from "../PageContent";
import { Alert } from "react-bootstrap";
import { ScaleModel } from "../../data/entities";

export function MyInspectionDetailsPage() {
  const { pathname } = useLocation();

  const [pageData, setPageData] = useState<
    InspectionDetails | undefined | null
  >(undefined);
  const [hasOvalityData, setHasOvalityData] = useState(false);
  const [inspectionScale, setInspectionScale] = useState<ScaleModel>();
  const id = Number.parseInt(
    getRouteParams(routes.myInspectionsDetails, pathname).get("id") ?? "0"
  );
  const params = useMemo(
    () => new Map<string, string>([["id", id.toString()]]),
    [id]
  );
  const buildMenu = useMemo(
    () => [
      { label: "Back", url: routes.myInspections, icon: "arrow-left" },
      {
        label: "Details",
        url: routes.myInspectionsDetails,
        params: new Map<string, string>([["id", id.toString()]]),
        activeMatch: routes.myInspectionsDetails,
      },
    ],
    [id]
  );
  const [submenus, setSubmenus] = useState<SubmenuNavigationItem[]>(buildMenu);

  useEffect(() => {
    const fetchData = async () => {
      const loadedData = await new InspectionService().getInspectionDetails(
        id.toString()
      );

      console.debug("Loaded Data: ", loadedData);

      setPageData(loadedData);
      setHasOvalityData(loadedData?.hasOvalityData ?? false);
    };
    const fetchScale = async () => {
      const scale = await new InspectionService().getScale(id);
      if (scale) setInspectionScale(scale);
    }
    setPageData(undefined);
    store.dispatch(setActiveInspectionId(undefined));
    fetchData().catch((error) => {});
    fetchScale().catch((error) => {});

    return () => {};
  }, [id]);

  useEffect(() => {
    if (hasOvalityData) {
      setSubmenus([
        ...buildMenu,
        {
          label: "Ovality",
          url: routes.myInspectionsOvality,
          params,
          activeMatch: routes.myInspectionsOvality,
        },
        {
          label: "OD",
          url: routes.myInspectionsOD,
          params,
          activeMatch: routes.myInspectionsOD,
        },
      ]);
    }
    return () => {};
  }, [hasOvalityData, params, buildMenu]);

  const alertMessage = pageData?.uiMessage ? (
    <Alert variant="warning" dismissible={false}>
      <p className="mb-0">{pageData?.uiMessage}</p>
    </Alert>
  ) : (
    <></>
  );

  let content: React.JSX.Element;
  if (pageData === undefined) content = <SpinnerDefault />;
  else if (pageData === null)
    content = (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>No inspection data to show.</em>
        </div>
      </SimpleCard>
    );
  else {
    content = (
      <>
        {alertMessage}
        <ExploreCard
          chartData={pageData.chart}
          tubing={pageData.tubing!}
          reference={pageData.reference!}
          inspectionDate={pageData.inspectionDate}
          overallThreshold={pageData.overallThreshold ?? 0}
        />
        <InspectionCriteria pageData={pageData} />
        <InspectionSummary pageData={pageData} />
        <FLWCard
          chartData={pageData.flwValues}
          scale={inspectionScale}
          reference={pageData.reference!}
          tubing={pageData.tubing!}
          flwLow={pageData.flwLow}
          flwHigh={pageData.flwHigh}
        />
        <InspectorList inspectors={pageData?.inspectors} />
        <InspectionNotes pageData={pageData} />
      </>
    );
  }

  return (
    <PageContent submenu={<Submenu items={submenus}></Submenu>}>
      {content}
    </PageContent>
  );
}

export default MyInspectionDetailsPage;

