import { useEffect, useState } from "react";
import { SimpleCard } from "../card/Card";
import SpinnerDefault from "../spinners/SpinnerDefault";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import {
  ChartDashboardItem,
  MeasurementUnit
} from "../../data/entities";
import { DashboardService, UserMeasurementService } from "../../services";
import { defaultChartColors } from "../../app/tools";

export function DashboardInspectionsChart() {
  const [items, setItems] = useState<ChartDashboardItem[] | null | undefined>(
    undefined
  );

  useEffect(() => {
    new DashboardService().getInspectionHistory().then((data) => {
      // Convert all values to the user's preferred system
      data = (data ?? []).map((i) => {
        i.value = UserMeasurementService.convertValue(i.value, MeasurementUnit.distanceLarge);
        return i;
      });
      setItems(data);
    });

    return () => {};
  }, []);

  const getChartSeries = () => {
    return Array.from(new Set(items?.map((item) => item.company)))?.map(
      (company, index) => (
        <ChartSeriesItem
          key={company}
          name={company}
          data={items?.filter((item) => item.company === company)}
          // field="value"
          aggregate={"sum"}
          type="column"
          categoryField="date"
          stack={true}
          color={defaultChartColors[index % defaultChartColors.length]}
        >
          <ChartSeriesItemTooltip
            visible={true}
            render={(c) => {
              return <div>{company}</div>;
            }}
          />
        </ChartSeriesItem>
      )
    );
  };

  const getContent = () => {
    if (!items) return <SpinnerDefault />;
    if (items.length === 0) return <em>No data to display.</em>;
    return <Chart className="chart">
      <ChartPlotArea></ChartPlotArea>
      <ChartLegend position="top"></ChartLegend>
      <ChartSeries>{getChartSeries()}</ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem
          title={{ text: `Length (${UserMeasurementService.getAbbreviation(MeasurementUnit.distanceLarge)})` }}
          labels={{ format: "n0", skip: 2, step: 2 }}
          line={{ visible: false }}
          axisCrossingValue={0}
        ></ChartValueAxisItem>
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          title={{ text: `Month` }}
          baseUnit="months"
          majorGridLines={{
            visible: false,
          }}
        ></ChartCategoryAxisItem>
      </ChartCategoryAxis>
    </Chart>;
  };

  return <SimpleCard className="mb-3">{getContent()}</SimpleCard>;
}

export default DashboardInspectionsChart;

