import { Measurement } from "../data/entities/Measurement";
import { MeasurementUnit } from "../data/entities/MeasurementUnit";
import { SystemOfMeasurement } from "../data/entities/SystemOfMeasurement";
import { UserService } from "../services";

interface ValueFormats {
  distanceLarge: string;
  distanceSmall: string;
  distanceTiny: string;
}

interface UnitAbbreviations {
  distanceLarge: string;
  distanceSmall: string;
  distanceTiny: string;
}

interface ConverterOptions {
  valueFormats: ValueFormats;
  unitAbbreviations: UnitAbbreviations;
}

export abstract class MeasurementConverterBase {
  system: SystemOfMeasurement;
  options: ConverterOptions;

  constructor(system: SystemOfMeasurement, options: ConverterOptions) {
    this.system = system;
    this.options = options;
  }

  getAbbreviation(unit: MeasurementUnit): string {
    let name = `${unit}` as keyof UnitAbbreviations;
    return this.options.unitAbbreviations[name];
  }

  abstract toMetric(measurement: Measurement): Measurement | undefined;
  abstract fromMetric(measurement: Measurement): Measurement | undefined;
  abstract convertToLarge(small: number): number | undefined;
  abstract convertToSmall(large: number): number | undefined;
}

class MetricConverter extends MeasurementConverterBase {
  constructor() {
    super(SystemOfMeasurement.metric,
      {
        valueFormats: {
          distanceLarge: "{0:N0}",
          distanceSmall: "{0:N0}",
          distanceTiny: "{0:N2}"
        },
        unitAbbreviations: {
          distanceLarge: "m",
          distanceSmall: "mm",
          distanceTiny: "mm"
        }
      });
  }
  toMetric(measurement: Measurement): Measurement | undefined {
    return measurement;
  }

  fromMetric(measurement: Measurement): Measurement | undefined {
    return measurement;
  }

  convertToLarge(small: number): number | undefined {
    return small / 1000;
  }

  convertToSmall(large: number): number | undefined {
    return large * 1000;
  }
}

class UsConverter extends MeasurementConverterBase {
  constructor() {
    super(SystemOfMeasurement.US,
      {
        valueFormats: {
          distanceLarge: "{0:N0}",
          distanceSmall: "{0:N0}",
          distanceTiny: "{0:N3}"
        },
        unitAbbreviations: {
          distanceLarge: "ft",
          distanceSmall: "in",
          distanceTiny: "\""
        }
      });
  }

  toMetric(measurement: Measurement): Measurement | undefined {
    switch (measurement.unit) {
      case MeasurementUnit.distanceLarge:
      case MeasurementUnit.distanceLargeAndSmall:
        return {
          system: SystemOfMeasurement.metric,
          unit: MeasurementUnit.distanceLarge,
          value: measurement.value / 3.28084
        };
      case MeasurementUnit.distanceSmall:
      case MeasurementUnit.distanceTiny:
        return {
          system: SystemOfMeasurement.metric,
          unit: MeasurementUnit.distanceSmall,
          value: measurement.value * 25.4
        };
      default:
        console.error('No conversion exists for unit of measurement: ' + measurement.unit);
    }
  }

  fromMetric(measurement: Measurement): Measurement | undefined {
    switch (measurement.unit) {
      case MeasurementUnit.distanceLarge:
      case MeasurementUnit.distanceLargeAndSmall:
        return {
          system: this.system,
          unit: MeasurementUnit.distanceLarge,
          value: measurement.value * 3.28084
        };
      case MeasurementUnit.distanceSmall:
      case MeasurementUnit.distanceTiny:
        return {
          system: this.system,
          unit: MeasurementUnit.distanceSmall,
          value: measurement.value / 25.4
        };
      default:
        throw new Error(`No conversion exists for unit of measurement: ${measurement.unit}`);
    }
  }

  convertToLarge(small: number): number | undefined {
    return small / 12;
  }

  convertToSmall(large: number): number | undefined {
    return large * 12;
  }
}



export class MeasurementConverter {
  converters = [
    new MetricConverter(),
    new UsConverter()
  ];

  toFriendlyString(
    measurement: Measurement,
    showValues: boolean = true,
    showUnits: boolean = true,
    system: (SystemOfMeasurement | undefined) = undefined
  ): string {
    const targetSystem = system ?? measurement.system;
    const converter = this.converters[targetSystem] as MeasurementConverterBase;
    const format = this.getFormat(measurement.unit, converter, showValues, showUnits);
    measurement = this.convert(measurement, targetSystem) ?? measurement;

    // Check if the format requires two values. 
    // If it does, it's a large then a remainder
    if (format.indexOf("{1") >= 0) {
      const largeFormat = this.getFormat(MeasurementUnit.distanceLarge, converter, true, false);
      const largeFormatted = this.format(largeFormat, measurement.value);
      const largeValue = Number.parseFloat(largeFormatted);

      // Determine the remainder
      const largeRemainder = measurement.value - largeValue;
      const smallValue = converter.convertToSmall(largeRemainder);

      return this.format(format, largeValue, smallValue!);
    }

    const formattedValue = this.format(format, measurement.value);
    return formattedValue;
  }

  getFormat(unit: MeasurementUnit, converter: MeasurementConverterBase, showValue: boolean = true, showUnit: boolean = true): string {
    if (unit === MeasurementUnit.distanceLargeAndSmall) {

      const large = this.buildFormat(
        converter.options.valueFormats.distanceLarge,
        converter.options.unitAbbreviations.distanceLarge,
        showValue,
        showUnit);

      let small = this.buildFormat(
        converter.options.valueFormats.distanceLarge,
        converter.options.unitAbbreviations.distanceSmall,
        showValue,
        showUnit);

      // Make small the first parameter
      small = small.replace("{0", "{1");

      // Format large and small
      return `${large} ${small}`;
    }

    return this.buildFormat(
      converter.options.valueFormats[unit],
      converter.options.unitAbbreviations[unit],
      showValue,
      showUnit
    );
  }

  private buildFormat(
    valueFormat: string,
    unitAbbreviation: string,
    showValue: boolean,
    showUnit: boolean
  ): string {
    return (
      (showValue ? valueFormat : "") +
      //No space between value and unit abbreviation
      (showUnit ? unitAbbreviation : "")
    );
  }

  private format(baseString: string, ...replaceValues: number[]): string {
    const matches = baseString.matchAll(/\{\d:N\d+\}/g);
    for (const match of matches) {
      const matchString = match.toString();
      const index = match.index;
      const number = replaceValues[index!];
      const decimals = RegExp(/N(\d+)/).exec(matchString);
      const maximumFractionDigits = parseInt(decimals![1]);
      const formattedNumber = number.toLocaleString('en-US', { maximumFractionDigits });
      baseString = baseString.replace(matchString, formattedNumber);
    }
    return baseString;
  }

  convert(measurement: Measurement, targetSystem: SystemOfMeasurement): Measurement | undefined {
    if (measurement.system === targetSystem) return measurement;

    const converter = this.converters[measurement.system];
    const targetConverter = this.converters[targetSystem];
    if (!converter) throw new ReferenceError(`Unable to get converter for measurement system "${measurement.system}".`);

    // Convert from to metric
    const metric = converter.toMetric(measurement);
    // Convert metric to target
    const target = targetConverter.fromMetric(metric!);

    // Return the result
    return target;
  }

  getConverter(system: SystemOfMeasurement): MeasurementConverterBase { 
    return this.converters[system];
  }

  getDefaultConverter(): MeasurementConverterBase { 
    return this.converters[UserService.getDefaultMeasurement()];
  }

}
