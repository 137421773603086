import React, { useEffect } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Measurement, SimpleCard, SpinnerDefault, TitlelessCard } from "../../../../components";
import { MeasurementUnit } from "../../../../data/entities/MeasurementUnit";
import { DataPointModel, DataPointValueModel, ScaleModel } from "../../../../data/entities";
import { MeasurementConverter } from "../../../../app/measurementConverter";

export interface FLWBaselineCardProps extends React.PropsWithChildren {
  chartData: (DataPointModel & DataPointValueModel)[];
  spinner: boolean;
  scale: ScaleModel | undefined;
  setSpinner: (value: boolean) => void;
}

export function FLWBaselineCard(props: FLWBaselineCardProps) {
  const { chartData } = props;

  // This chart should use the system data, not converted
  const defaultConverter = (new MeasurementConverter()).getDefaultConverter();
  
  // when chart data changes that is the end of the loading. If updating is true 
  useEffect(() => {
    props.setSpinner(false);
  }, [props, chartData]);
  
  if (chartData === undefined) return <SpinnerDefault />;
  else if (chartData === null)
    return <SimpleCard title="Failed to load data."></SimpleCard>;
  else if ((chartData?.length ?? 0) > 0) {
    const categoryData = chartData.map((p) => p.startPosition);
    const flwBaselineData = chartData.map((p) => p.flwBaseline);
    const flwData = chartData.map((p) => p.flw);

    const flwDifference = Math.abs((props.scale?.flwHighValue ?? 0) - (props.scale?.flwLowValue ?? 0));

    return (
      <TitlelessCard className="mb-3">
      <SpinnerDefault show={props.spinner} fullscreen={true} />
        <Chart className="chart">
          <ChartLegend visible={true} position="top" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              title={{ text: `Length (${defaultConverter.getAbbreviation(MeasurementUnit.distanceLarge)})` }}
              categories={categoryData}
              majorGridLines={{ visible: false }}
              majorTicks={{
                visible: true,
                step: Math.round(chartData.length / 10 / 10) * 10,
              }}
              minorTicks={{
                visible: false,
              }}
              labels={{ step: Math.round(chartData.length / 10 / 10) * 10, format: "{0:N0}" }}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              title={{ text: `Raw Value` }}
              visible={true}
              reverse={true}
              // min = {min - ((max-min) * 1) < 0 ? 0 : min - ((max-min) * 1)}
              min = {props.scale?.flwLowValue! - flwDifference}
              // max = {max + ((max-min) * 1)}
              max = {props.scale?.flwHighValue! + flwDifference}
              labels={{
                visible: true,
                format: "{0:N2}"
              }}
              line={{ visible: false }}
              majorGridLines={{ visible: true }}
              minorGridLines={{ visible: false }}
              majorTicks={{ visible: false }}
              minorTicks={{ visible: false }}
              axisCrossingValue={ 1000 /* puts it always at the bottom */ }
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem
              name="Nominal Wall Thickness"
              data={flwBaselineData}
              type="area"
              color={"#00000033"}
              markers={{ visible: false }}
            />
            <ChartSeriesItem
              name="Actual Wall Thickness"
              data={flwData}
              type="line"
              color={"#0D698B"}
              width={1}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip
                visible={true}
                render={(c) => {
                  return (
                    <div className="text-light text-left">
                      <strong>
                        <Measurement
                          unit={MeasurementUnit.distanceLarge}
                          value={c.point.category as number}
                        />
                      </strong>
                      {" : "}
                      {Math.round(c.point.value as number * 100) / 100}
                    </div>
                  );
                }}
              />
            </ChartSeriesItem>
          </ChartSeries>
        </Chart>
        <div className="row">
          <div className="col-6 text-start text-sm text-soft">core</div>
          <div className="col-6 text-end text-sm text-soft">tail</div>
        </div>
      </TitlelessCard>
    );
  } else return null;
}


