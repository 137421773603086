 import { useOutletContext, useParams } from "react-router-dom";
import {
  BlockButton,
  SimpleCard,
  SpinnerDefault,
  TextWithValidation,
} from "../../../components";
import { useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import { toYearMonthDayTime } from "../../../app/dateHelpers";
import ProcessFLWBaselineModel from "../../../data/entities/ProcessFLWBaselineModel";
import { FLWBaselineCard } from "./components/FLWBaselineCard";
import { getUuid } from "../../../app/tools";
import { InspectionsPageContext } from "../InspectionsPage";
import { ScaleModel } from "../../../data/entities";
import { InspectionService } from "../../../services";

export function InspectionsProcessFlwBaseline() {
  const context: InspectionsPageContext = useOutletContext();
  context.setTitle("FLW Baseline");

  const params = useParams();
  const id = params["id"] ?? "";

  const defaultValue = "0";

  const [flwBaselineModel, setFlwBaselineModel] =
    useState<ProcessFLWBaselineModel | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [inspectionScale, setInspectionScale] = useState<ScaleModel>();
  
  async function adjustFlwBaselineDataButtonClick() {
    if (flwBaselineModel === null) return;
    setShowSpinner(true);
    const ok = await new ProcessService().postProcessFLWBaseline(
      parseInt(id),
      flwBaselineModel
    );

    if (!ok) return;

    // Reload data after FLW Baseline adjustment.
    loadData(parseInt(id));
  }

  const loadData = async (id: number) => {
    await new ProcessService()
    .getProcessFLWBaseline(id)
    .then((data) => {
      setFlwBaselineModel((prevState) => ({
        ...(prevState as ProcessFLWBaselineModel),
        chart: data?.chart!,
        processes: data?.processes!,
      }));
      setIsDataLoaded(true);
    })
    .catch((error) => {
      console.error("Failed to fetch process FLW Baseline data: ", error);
    });
  }

  useEffect(() => {
    loadData(parseInt(id));
    try {
      const fetchScale = async () => {
        const scale = await new InspectionService().getScale(parseInt(id));
        if (scale) 
          setInspectionScale(scale);
      }
      fetchScale();
    } catch (error) {
      console.error("Failed to fetch scale: ", error);
    }
  }, [id]);

  const handleIntChange = (field: string, input: string) => {
    setFlwBaselineModel((prevState) => ({
      ...(prevState as ProcessFLWBaselineModel),
      [field]: parseInt(input),
    }));
  };

  const handleFloatChange = (field: string, input: string) => {
    setFlwBaselineModel((prevState) => ({
      ...(prevState as ProcessFLWBaselineModel),
      [field]: parseFloat(input),
    }));
  };

  if (!isDataLoaded) return <SpinnerDefault />;

  return (
    <>
      <SimpleCard className="mb-3 p-0" title="Adjust FLW Baseline.">
        <div className="row">
          <div className="col-12">
            <p>
              The baseline FLW for a tubing may need to be adjusted to better
              detect anomalies as the side of the pipe changes. Use the form
              below to set how the pipe's baseline FLW changes throughout its
              length.
            </p>

            <div className="row">
              <div className="col-12 col-md-6">
                <strong>From</strong>
                <TextWithValidation
                  label="Start Position (m)"
                  id=""
                  required={false}
                  initialValue={defaultValue}
                  onChangeText={(input) => {
                    handleIntChange("startPosition", input);
                  }}
                  type="number"
                ></TextWithValidation>
                <TextWithValidation
                  label="Start Baseline"
                  id=""
                  required={false}
                  initialValue={defaultValue}
                  onChangeText={(input) => {
                    handleFloatChange("startBaseline", input);
                  }}
                  type="number"
                ></TextWithValidation>
              </div>
              <div className="col-12 col-md-6">
                <strong>To</strong>
                <TextWithValidation
                  label="End Position (m)"
                  id=""
                  required={false}
                  initialValue={defaultValue}
                  onChangeText={(input) => {
                    handleIntChange("endPosition", input);
                  }}
                  type="number"
                ></TextWithValidation>
                <TextWithValidation
                  label="End Baseline"
                  id=""
                  required={false}
                  initialValue={defaultValue}
                  onChangeText={(input) => {
                    handleFloatChange("endBaseline", input);
                  }}
                  type="number"
                ></TextWithValidation>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
                <BlockButton
                  label="Adjust FLW Baseline"
                  onClick={adjustFlwBaselineDataButtonClick}
                  disabled={false}
                ></BlockButton>
              </div>
            </div>
          </div>
        </div>
      </SimpleCard>
      <>
        <FLWBaselineCard chartData={flwBaselineModel?.chart.points!} spinner={showSpinner} setSpinner={setShowSpinner} scale={inspectionScale} />
      </>
      <SimpleCard className="mb-3 p-0" title="FLW Baseline adjustment history.">
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {flwBaselineModel?.processes && flwBaselineModel?.processes.map((processDataHistory) => (
              <tr className="clickable" key={getUuid()}>
                <td>{toYearMonthDayTime(processDataHistory.createdDate)}</td>
                <td>{processDataHistory.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </SimpleCard>
    </>
  );
}

export default InspectionsProcessFlwBaseline;

