import React, { useEffect, useState } from "react";
import { SimpleCard } from "../../components/card/Card";
import SpinnerDefault from "../../components/spinners/SpinnerDefault";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { FLWChartValue } from "../../data/entities/FLWChartValueModel";
import { Measurement } from "../../components";
import { UserMeasurementService } from "../../services";
import { ScaleModel, MeasurementUnit } from "../../data/entities";

export interface FLWCardProps extends React.PropsWithChildren {
  chartData: FLWChartValue[];
  tubing: string;
  scale: ScaleModel | undefined;
  reference: string;
  flwLow?: number;
  flwHigh?: number;
}

export function FLWCard(props: FLWCardProps) {
  const { chartData, tubing, reference } = props;

  const [converted, setConverted] = useState(false);
  
  const valueFormat = UserMeasurementService.getFormat(MeasurementUnit.distanceTiny);

  useEffect(() => {
    const isConverted = chartData.some((item) => item.isConverted) ?? false;
    setConverted(isConverted);
  }, [chartData]);

  if (chartData === undefined) return <SpinnerDefault />;
  else if (chartData === null)
    return <SimpleCard title="Failed to load data."></SimpleCard>;
  else if ((chartData?.length ?? 0) > 0) {
    const categoryData = chartData.map((p) => p.startPosition);
    const flwBaselineData = chartData.map((p) => p.flwBaseline);
    const flwData = chartData.map((p) => p.flw);
    const flwDifference = Math.abs(props.flwLow! - props.flwHigh!);

    return (
      <SimpleCard title={`FLW ${tubing} : ${reference}`} className="mb-3">
        <Chart className="chart">
          <ChartLegend visible={true} position="top" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={categoryData}
              title={{ text: `Length (${UserMeasurementService.getAbbreviation(MeasurementUnit.distanceLarge)})` }}
              majorGridLines={{ visible: false }}
              majorTicks={{
                visible: true,
                step: Math.round(chartData.length / 40),
              }}
              minorTicks={{
                visible: false,
              }}
              crosshair={{ visible: true, color: "#ff6f00" }}
              labels={{ step: Math.round(chartData.length / 10), format: "#" }}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              visible={true}
              title={{ text: `Thickness (${UserMeasurementService.getAbbreviation(MeasurementUnit.distanceTiny)})` }}
              reverse={!converted}
              min={props.flwLow && props.flwHigh ?
                (Math.min(props.flwLow, props.flwHigh) - flwDifference) :
                null}
              max={props.flwLow && props.flwHigh ?
                (Math.max(props.flwLow, props.flwHigh) + flwDifference) :
                null}
              labels={{
                visible: true,
                format: converted ? valueFormat : "{0:N1}",
              }}
              line={{ visible: false }}
              majorGridLines={{ visible: true }}
              minorGridLines={{ visible: false }}
              majorTicks={{ visible: false }}
              minorTicks={{ visible: false }}
              axisCrossingValue={
                converted ? 0 : 1000 /* puts it always at the bottom */
              }
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem
              name="Nominal Wall Thickness"
              data={flwBaselineData}
              type="area"
              color={"#00000033"}
              markers={{ visible: false }}
            />
            <ChartSeriesItem
              name="Actual Wall Thickness"
              data={flwData}
              type="line"
              color={"#0D698B"}
              width={1}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip
                visible={true}
                render={(c) => {
                  if (converted) {
                    return (
                      <div className="text-light text-left">
                        <strong>
                          <Measurement
                            unit={MeasurementUnit.distanceLarge}
                            value={c.point.category as number}
                            convert={false}
                          />
                        </strong>
                        {" : "}
                        <Measurement
                          unit={MeasurementUnit.distanceTiny}
                          value={c.point.value as number}
                          convert={false}
                        />
                      </div>
                    );
                  }

                  return (
                    <div className="text-light text-left">
                      <strong>
                        <Measurement
                          unit={MeasurementUnit.distanceLarge}
                          value={c.point.category as number}
                          convert={false}
                        />
                      </strong>
                      {" : "}
                      {c.point.value as number}
                    </div>
                  );
                }}
              />
            </ChartSeriesItem>
          </ChartSeries>
        </Chart>
        <div className="row">
          <div className="col-6 text-start text-sm text-soft">core</div>
          <div className="col-6 text-end text-sm text-soft">tail</div>
        </div>
      </SimpleCard>
    );
  } else return null;
}


