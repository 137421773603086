import UserService from "./UserService";
import {
  Measurement,
  MeasurementUnit,
  SystemOfMeasurement
} from "../data/entities";
import {
  MeasurementConverter,
  MeasurementConverterBase
} from "../app/measurementConverter";

export class UserMeasurementService {
  private static converterService = new MeasurementConverter();

  static getSystem(): SystemOfMeasurement {
    return UserService.getPreferredMeasurement();
  }

  static getDefaultSystem(): SystemOfMeasurement {
    return UserService.getDefaultMeasurement();
  }

  static getConverter(): MeasurementConverterBase {
    return this.converterService.converters[this.getSystem()];
  }

  static convert(measurement: Measurement): Measurement {
    let system = this.getSystem();
    return this.converterService.convert(measurement, system) ?? measurement;
  }

  static convertValue(value: number, unit: MeasurementUnit, system: SystemOfMeasurement | undefined = undefined): number {
    return this.convert({
      system: system ?? this.getDefaultSystem(),
      unit: unit,
      value: value
    }).value;
  }

  static toFriendlyString(measurement: Measurement, showValue: boolean = true, showUnit: boolean = true): string {
    let system = this.getSystem();
    return this.converterService.toFriendlyString(measurement, showValue, showUnit, system);
  }

  static getFormat(unit: MeasurementUnit, showValue: boolean = true, showUnit: boolean = true): string {
    let converter = this.getConverter();
    return this.converterService.getFormat(unit, converter, showValue, showUnit);
  }

  static getAbbreviation(unit: MeasurementUnit): string {
    let converter = this.getConverter();
    return converter.getAbbreviation(unit);
  }
}

export default UserMeasurementService;
